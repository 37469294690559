@import "~@finantix/core/lib/scss/styles/all";

:root {
    --fx-mailList-font-family:                             var(--fx-font-family-base, OpenSans, Arial, Helvetica);
    --fx-mailList-font-size:                               var(--fx-font-size-2a, 14px);
    --fx-mailList-padding:                                 0;
    --fx-mailList-item-left-col-width:                     8px;
    --fx-mailList-item-left-col-border-width:              4px;
    --fx-mailList-item-left-col-border-color:              var(--fx-color-primary-125, #2F6FBB);
    --fx-mailList-item-padding-vertical:                   var(--fx-padding-2a, 8px);
    --fx-mailList-item-padding-horizontal:                 var(--fx-padding-2b, 12px);
    --fx-mailList-item-border-width:                       1px;
    --fx-mailList-item-border-color:                       var(--fx-color-gray-8, #EBEFF2);
    --fx-mailList-item-color-bg:                           transparent;
    --fx-mailList-item-hovered-color-bg:                   var(--fx-color-primary-50, #ABCEF9);
    --fx-mailList-item-active-color-bg:                    var(--fx-color-primary-25, #D6E7FC);
    --fx-mailList-item-checked-color-bg:                   var(--fx-color-primary-12, #EAF4FD);
    --fx-mailList-itemThread-active-color-bg:              var(--fx-color-primary-12, #EAF4FD);
    --fx-mailList-itemThread-checked-color-bg:             var(--fx-color-primary-12, #EAF4FD);
    --fx-mailList-itemSubject-color-fg:                    var(--fx-color-primary-125, #2F6FBB);
    --fx-mailList-itemSubject-font-size:                   var(--fx-font-size-2a, 14px);
    --fx-mailList-itemFrom-color-fg:                       var(--fx-font-color, #121F26);
    --fx-mailList-itemFrom-font-size:                      var(--fx-font-size-2a, 14px);
    --fx-mailList-itemDate-color-fg:                       var(--fx-color-gray-40, #889AA5);
    --fx-mailList-itemDate-font-size:                      var(--fx-font-size-1a, 12px);
    --fx-mailList-itemBodyPreview-color-fg:                var(--fx-color-gray-60, #425866);
    --fx-mailList-itemBodyPreview-font-size:               var(--fx-font-size-1a, 12px);
    --fx-mailList-itemBodyPreview-margin-right:            var(--fx-spacing-2a, 8px);
    --fx-mailList-itemWeblinkIcon-color-fg:                var(--fx-color-gray-40, #889AA5);
    --fx-mailList-itemWeblinkIcon-font-size:               var(--fx-font-size-4a, 18px);
    --fx-mailList-itemAttachmentIcon-color-fg:             var(--fx-color-primary-125, #2F6FBB);
    --fx-mailList-itemAttachmentIcon-font-size:            var(--fx-font-size-4a, 18px);
    --fx-mailList-itemAttachmentIcon-margin-right:         var(--fx-spacing-2a, 8px);
    --fx-mailList-itemActiveSelector-width:                2px;
    --fx-mailList-itemActiveSelector-color-bg:             var(--fx-color-primary-125, #2F6FBB);
    --fx-mailList-itemContent-margins:                     0;
    --fx-mailList-itemCheckboxIcon-margin-left:            0;
    --fx-mailList-markerIcon-font-size:                    var(--fx-font-size-4a, 18px);
    --fx-mailList-markerIcon-color-fg:                     var(--fx-color-grass-100, #02734d);
    --fx-mailList-viewIcon-font-size:                      var(--fx-font-size-4a, 18px);
    --fx-mailList-viewIcon-color-fg:                       var(--fx-color-primary-125, #2F6FBB);
}

@mixin fx-mail-list (
    $font-family:                                       var(--fx-mailList-font-family),
    $font-size:                                         var(--fx-mailList-font-size),
    $mail-list-padding:                                 var(--fx-mailList-padding),
    $mail-list-left-col-width:                          var(--fx-mailList-item-left-col-width),
    $mail-list-left-col-border-width:                   var(--fx-mailList-item-left-col-border-width),
    $mail-list-left-col-border-color:                   var(--fx-mailList-item-left-col-border-color),
    $mail-item-padding-vertical:                        var(--fx-mailList-item-padding-vertical),
    $mail-item-padding-horizontal:                      var(--fx-mailList-item-padding-horizontal),
    $mail-item-border-width:                            var(--fx-mailList-item-border-width),
    $mail-item-border-color:                            var(--fx-mailList-item-border-color),
    $mail-item-bg:                                      var(--fx-mailList-item-color-bg),
    $mail-item-hovered-bg:                              var(--fx-mailList-item-hovered-color-bg),
    $mail-item-active-bg:                               var(--fx-mailList-item-active-color-bg),
    $mail-item-thread-active-bg:                        var(--fx-mailList-itemThread-active-color-bg),
    $mail-subject-font-size:                            var(--fx-mailList-itemSubject-font-size),
    $mail-subject-color:                                var(--fx-mailList-itemSubject-color-fg),
    $mail-from-font-size:                               var(--fx-mailList-itemFrom-font-size),
    $mail-from-color:                                   var(--fx-mailList-itemFrom-color-fg),
    $mail-date-font-size:                               var(--fx-mailList-itemDate-font-size),
    $mail-date-color:                                   var(--fx-mailList-itemDate-color-fg),
    $mail-preview-font-size:                            var(--fx-mailList-itemBodyPreview-font-size),
    $mail-preview-color:                                var(--fx-mailList-itemBodyPreview-color-fg),
    $mail-preview-margin-right:                         var(--fx-mailList-itemBodyPreview-margin-right),
    $mail-weblinkIcon-font-size:                        var(--fx-mailList-itemWeblinkIcon-font-size),
    $mail-weblinkIcon-color:                            var(--fx-mailList-itemWeblinkIcon-color-fg),
    $mail-attachmentIcon-font-size:                     var(--fx-mailList-itemAttachmentIcon-font-size),
    $mail-attachmentIcon-color:                         var(--fx-mailList-itemAttachmentIcon-color-fg),
    $mail-attachmentIcon-margin-right:                  var(--fx-mailList-itemAttachmentIcon-margin-right),
    $mail-collapseIcon-font-size:                       var(--fx-mailList-itemAttachmentIcon-font-size),
    $mail-collapseIcon-color:                           var(--fx-mailList-itemAttachmentIcon-color-fg),
    $mail-item-content-margins:                         var(--fx-mailList-itemContent-margins),
    $mail-item-checkboxIcon-margin-left:                var(--fx-mailList-itemCheckboxIcon-margin-left),
    $mail-markerIcon-font-size:                         var(--fx-mailList-markerIcon-font-size),
    $mail-markerIcon-color:                             var(--fx-mailList-markerIcon-color-fg),
    $mail-viewIcon-font-size:                           var(--fx-mailList-viewIcon-font-size),
    $mail-viewIcon-color:                               var(--fx-mailList-viewIcon-color-fg),
    $mail-item-thread-checked-bg:                       var(--fx-mailList-itemThread-checked-color-bg),
    $mail-item-checked-bg:                              var(--fx-mailList-item-checked-color-bg)
) {
    fx-mail-list {
        height: 100%;
        overflow: hidden;
        max-height: 100%;
        display: block;

        .mail-list {
            font-family: $font-family;
            display: flex;
            height: 100%;
            flex-grow: 1;
            flex-direction: column;
            overflow-y: auto;
            position: relative;

            .error-message {
                text-align: center;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .mail-item {
                font-size: $font-size;
                padding: 0;
                display: flex;
                flex-direction: row;
                border-bottom: solid $mail-item-border-width $mail-item-border-color;
    
                &:hover {
                    background-color: $mail-item-hovered-bg;
                }
    
                &.active {
                    background-color: $mail-item-active-bg;

                    &:hover {
                        background-color: $mail-item-hovered-bg;
                    }

                    &:not(.thread-child) {
                        .mail-item-col {
                            &:first-child{
                                border-left-color: $mail-list-left-col-border-color;
                            }
                        }
                    }
                }

                &.checked {
                    background-color: $mail-item-checked-bg;

                    &:hover {
                        background-color: $mail-item-hovered-bg;
                    }
                }

                &.spinner {
                    border: none;
                    min-height: 50px;

                    &.full-height {
                        height: 100%;
                    }
                }
    
                &.thread {
                    .mail-item-row {
                        &-counter {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 100%;
    
                            i {
                                font-size: $mail-collapseIcon-font-size;
                                color: $mail-collapseIcon-color;
                                height: 100%;
                                display: none;
                                align-items: center;
                            }
                        }
                    }
    
                    &-child {
                        .mail-item-row {
                            &-preview {
                                -webkit-line-clamp: 1;
                            }
                        }
                    }
                }
    
                &-col {
                    display: flex;
                    flex-direction: column;
                    padding: $mail-item-padding-vertical $mail-item-padding-horizontal $mail-item-padding-vertical 0;
                    flex: 0;
    
                    &:first-child{
                        width: $mail-list-left-col-width;
                        min-width: $mail-list-left-col-width;
                        border-left: solid $mail-list-left-col-border-width transparent;
                        padding: $mail-item-padding-vertical 0;

                        .mail-item-row {
                            flex: 1;
                        }
                    }


                    &:nth-child(2) {
                        padding-right: 0;
                    }

                    &:last-child{
                        flex: 1;
                        min-width: 0;
                    }
                }
    
                &-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: $mail-item-content-margins;
                    min-width: 0;
    
                    &-from {
                        width: 100%;
                        overflow: hidden;
                        flex-direction: row;
                        align-items: center;
                        display: flex;
                        flex-direction: row;

                        span {
                            font-size: $mail-from-font-size;
                            color: $mail-from-color;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                            overflow: hidden;
                        }   
                    }

                    &-subject {
                        width: 100%;
                        overflow: hidden;
                        flex-direction: row;
                        align-items: center;
                        display: flex;
                        flex-direction: row;

                        span {
                            font-size: $mail-subject-font-size;
                            color: $mail-subject-color;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                            overflow: hidden;
                        }
                    }
    
                    &-date-time {
                        flex-direction: row;
                        align-items: center;
                        display: flex;
                        flex-direction: row;

                        span {
                            font-size: $mail-date-font-size;
                            color: $mail-date-color;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                            overflow: hidden;
                        }   
                    }

                    &-attachment {
                            font-size: $mail-attachmentIcon-font-size;
                            color: $mail-attachmentIcon-color;
                            align-items: center;
                            display: flex;

                            &:not(:last-of-type) {
                                margin-right: $mail-attachmentIcon-margin-right;
                            }
                    }
    
                    &-preview {
                        width: 100%;
                        overflow: hidden;
                        flex-direction: row;
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        
                        span {
                            font-size: $mail-preview-font-size;
                            color: $mail-preview-color;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 100%;
                            margin-right: $mail-preview-margin-right;
                        }
                    }

                    &-weblink {
                        align-items: center;
                        display: flex;
                        font-size: $mail-weblinkIcon-font-size;
                        color: $mail-weblinkIcon-color;
                        cursor: pointer;
                    }

                    &-checkbox {
                        align-items: center;
                        display: flex;
                        font-size: $mail-weblinkIcon-font-size;
                        color: $mail-weblinkIcon-color;
                        margin-left: $mail-item-checkboxIcon-margin-left;
                        padding-right: 8px;

                        .fx-check-box {
                            cursor: pointer;
                            align-items: flex-start;
                            padding-top: 2px;
                        }
                    }

                    &-marker {
                        align-items: center;
                        display: flex;
                        font-size: $mail-markerIcon-font-size;
                        color: $mail-markerIcon-color;

                        &:not(:last-of-type) {
                            margin-right: $mail-attachmentIcon-margin-right;
                        }
                    }

                    &-view {
                        align-items: center;
                        display: flex;
                        font-size: $mail-viewIcon-font-size;
                        color: $mail-viewIcon-color;
                        cursor: pointer;

                        &:not(:last-of-type) {
                            margin-right: $mail-attachmentIcon-margin-right;
                        }
                    }
                }
            }
    
            .thread-item {
                display: flex;
                flex-direction: column;

                &.active {
                    background-color: $mail-item-thread-active-bg;

                    > .mail-item.thread {
                        background-color: $mail-item-active-bg;

                        .mail-item-col {
                            &:first-child:nth-last-child(2){
                                border-left-color: $mail-list-left-col-border-color;
                            }
                        }
                    }
                }

                &.expanded {
                    .mail-item-row-counter {
                        i.open {
                            display: flex;
                        }
                    }
                }

                &:not(.expanded) {
                    .mail-item.thread {
                        .mail-item-row-counter {
                            i.close {
                                display: flex;
                            }
                        }
                    }
    
                    .mail-item.thread-child {
                        display: none;
                    }
                }
            }
    
            .fx-btn-xs {
                min-width: 24px;
                min-height: 24px;
                width: 24px;
                height: 24px;
    
                > i {
                    font-size: 18px;
                }
            }
        }
    }
}